
<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMutationObserver } from '@vueuse/core'
import { KsButton } from '@aschehoug/kloss'
import { isElement } from '~/utils/dom'

const { t } = useI18n()
const scroller = useTemplateRef('scroller')
const index = ref(0)

const { nav } = defineProps<{
  nav: 'below' | 'floating'
}>()

const navClasses = computed(() => ({
  'flex justify-center items-center pt-8': nav === 'below',
  'absolute bottom-1/2 translate-y-1/2 left-8 right-8 flex justify-between items-center gap-6 transition-opacity': nav === 'floating',
}))

let observer: IntersectionObserver

watch((scroller), (el) => {
  if (!el) return

  observer = new IntersectionObserver((entries) => {
    if (!scroller.value) return

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        index.value = [...el.children].indexOf(entry.target)
      }
    })
  }, {
    root: scroller.value,
    rootMargin: '-50%',
  })

  onMutation()
})

function scrollTo(n: number, opt?: { focus: boolean }) {
  const item = scroller
    .value
    ?.children
    .item(n)

    item?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    })

    if (opt?.focus) item?.focus?.()
}

function scrollBy(n: number, opt?: { focus: boolean }) {
  scrollTo(index.value + n, opt)
}

function onMutation(mutations?: MutationRecord[]) {
  if (!scroller.value || !observer) return

  if (!mutations) {
    for (const node of scroller.value.childNodes) {
      if (isElement(node)) {
        observer.observe(node)
      }
    }
  }

  else {
    mutations.forEach(({ removedNodes, addedNodes }) => {
      for (const node of removedNodes) {
        if (isElement(node)) {
          observer.unobserve(node)
        }
      }

      for (const node of addedNodes) {
        if (isElement(node)) {
          observer.observe(node)
        }
      }
    })
  }
}

useMutationObserver(scroller, onMutation, { childList: true })
</script>

<template>
  <div class="relative grid grid-rows-1">
    <div
      ref="scroller"
      tabindex="0"
      class="relative grid snap-x snap-mandatory auto-cols-max grid-flow-col grid-rows-1 items-stretch gap-[--carousel-spacing] overflow-y-clip overflow-x-scroll scroll-smooth px-[50%] py-1 focus-visible:ring [&>*]:snap-center [&>*]:snap-always"
      :style="{
        scrollbarWidth: 'none',
      }"
      @keydown.left.prevent="scrollBy(-1)"
      @keydown.right.prevent="scrollBy(1)"
    >
      <slot
        name="pre"
        :scroll-to
        :scroll-by
        :index
      />
      <slot
        :scroll-to
        :scroll-by
        :index
      />
    </div>
    <nav
      class="pointer-events-none transition-[opacity,visibility]"
      :class="[navClasses, { 'invisible opacity-0': $slots.pre && index === 0 }]"
    >
      <KsButton
        data-name="carousel-nav-left"
        class="carousel-nav-button pointer-events-auto transition-[opacity,visibility]"
        :class="{ '!pointer-events-none invisible opacity-0': !$slots.pre && index === 0 }"
        icon-left="arrow-left"
        :variant="nav === 'below' ? 'border' : 'secondary'"
        shape="round"
        size="large"
        :title="t('previous')"
        :style="{
          '--ks-border': 'var(--theme-60,black)',
        }"
        @click="scrollBy(-1)"
      />
      <div class="flex items-center px-2 tabular-nums">
        <slot
          name="counter"
          :index
        />
      </div>
      <KsButton
        data-name="carousel-nav-right"
        class="carousel-nav-button pointer-events-auto transition-[opacity,visibility]"
        :class="{ 'pointer-events-none invisible opacity-0': index + 1 === scroller?.childElementCount }"
        icon-left="arrow-right"
        :variant="nav === 'below' ? 'border' : 'secondary'"
        shape="round"
        size="large"
        :title="t('next')"
        :style="{
          '--ks-border': 'var(--theme-60,black)',
        }"
        @click="scrollBy(1)"
      />
    </nav>
  </div>
</template>
