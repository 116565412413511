<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { Settings } from 'luxon'
import { gradesSorted } from '~/utils/gradeSorter'
import { FOCUSABLE } from '~/utils/dom'
import useProductStore from '~/stores/product'
import { useMessagesStore } from '~/stores/messages'
import useLicenseControlStore from '~/stores/licenseControl'
import useGroupsStore from '~/stores/groups'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useRouteMeta } from '~/composables/useRouteMeta'
import { useAppColor } from '~/composables/useAppColor'
import useAppBanner from '~/composables/banner'
import PreviewOverlay from '~/components/paywall/PreviewOverlay.vue'
import PaywallOverlay from '~/components/paywall/PaywallOverlay.vue'
import WizardDialog from '~/components/onboarding/WizardDialog.vue'
import FeedbackButton from '~/components/feedback/FeedbackButton.vue'
import DialogRenderer from '~/components/dialogs/DialogRenderer.vue'
import AppHeader from '~/components/AppHeader.vue'
import AppFooter from '~/components/AppFooter.vue'

const route = useRoute()
const router = useRouter()
const { getProducts } = useProductStore()
const { loadGroups } = useGroupsStore()
const { getMessages } = useMessagesStore()
const { hasAccessRestricted } = storeToRefs(useLicenseControlStore())
const { selectedGrade } = storeToRefs(useFilterStore())
const {
  userPreferredLanguage,
  userPreferredGrade,
  userRelevantGrades,
  userOrganization,
  isAuthenticated,
  hasLoadedUser,
  needsOrganization,
} = storeToRefs(useAuthStore())

const { locale, t } = useI18n()
const { banner } = useAppBanner()

const { hideAppFooter, hideAppHeader } = useRouteMeta()

const { bgColor, themeColor } = useAppColor()

const focusFirstFocusableElement = () => {
  const appContent = document.querySelector('#app-content')
  if (!appContent)
    return
  const firstFocusableElement = appContent.querySelectorAll(FOCUSABLE).item(0)
  if (!firstFocusableElement)
    return
  (firstFocusableElement as HTMLElement).focus()
}

const appContentClasses = computed(() => [
  'bg-[--app-bg] before:content-[\'\'] before:fixed before:top-0 before:-z-10 before:h-44 before:inset-x-0 before:bg-[--app-theme]',
  ...(!hideAppFooter.value ? ['mb-12'] : []),
  ...(hasAccessRestricted.value ? ['max-h-screen', 'overflow-hidden'] : []),
])

const hasPreviewOverlay = computed(() => {
  const hasPreview = !!router.currentRoute.value?.meta?.preview
  return hasPreview && hasLoadedUser.value && !isAuthenticated.value
})

onMounted(() => {
  getMessages()
})

watch(userPreferredLanguage, (newPreferredLanguage) => {
  locale.value = newPreferredLanguage
  Settings.defaultLocale = userPreferredLanguage.value || PreferredLanguage.Bokmal
}, { immediate: true })

watch([hasLoadedUser, userOrganization], () => {
  if (!isAuthenticated.value)
    return
  if (needsOrganization.value)
    return
  getProducts()
  loadGroups()
})

watch([hasLoadedUser, route], async () => {
  await router.isReady()
  const gradeParam = router.currentRoute.value?.query?.grade as GradeCode | undefined
  const validGrade = gradeParam && gradesSorted.includes(gradeParam) ? gradeParam : false
  selectedGrade.value = validGrade || userPreferredGrade.value || userRelevantGrades.value[0]
}, { deep: true, flush: 'pre' })
</script>

<template>
  <a
    v-if="banner"
    href="#app-content"
    tabindex="1"
    class="fixed z-50 -translate-x-full -translate-y-full whitespace-pre-line rounded-lg bg-seagreen-40 p-2 font-medium text-white focus:translate-x-4 focus:translate-y-4 focus:ring focus:ring-offset-2"
    @click.prevent.stop="focusFirstFocusableElement"
    v-text="t('jumpToContent')"
  />
  <AppHeader v-if="!hideAppHeader" />
  <div :class="TeleportationTarget.AppTop.replace('.', '')" />
  <main
    id="app-content"
    class="mx-auto w-full grow"
    :class="appContentClasses"
    :style="{
      '--app-theme': `rgb(var(${themeColor?.var}))`,
      '--app-bg': `rgb(var(${bgColor?.var}))`,
    }"
  >
    <PreviewOverlay v-if="hasPreviewOverlay" />
    <PaywallOverlay v-else-if="hasAccessRestricted" />
    <router-view />
  </main>
  <AppFooter v-if="!hideAppFooter" />
  <WizardDialog />
  <DialogRenderer />
  <div
    id="breakpointCheck"
    class="!h-0 max-h-0 min-h-0 w-0 xs:w-1 sm:w-2 md:w-3 lg:w-4 xl:w-5"
  />
  <FeedbackButton />
</template>
