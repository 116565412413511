<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { faBackpack, faCookie, faCopyright, faHeadphones, faLampDesk, faLock, faUniversalAccess } from '@fortawesome/pro-regular-svg-icons'
import { KsButton } from '@aschehoug/kloss'
import FeedbackButton from '~/components/feedback/FeedbackButton.vue'

const { t } = useI18n()

const webshopUrl = import.meta.env.VITE_WEBSHOP_TRIALURL
const supportUrl = import.meta.env.VITE_WEBSHOP_SUPPORT
const whitelistUrl = `${import.meta.env.VITE_AUNIVERS_SITEURL}/hvitlisting`
const privacyUrl = `${import.meta.env.VITE_AUNIVERS_SITEURL}/personvern`
const accessibilityUrl = `${import.meta.env.VITE_AUNIVERS_SITEURL}/uu`
const copyrightUrl = `${import.meta.env.VITE_AUNIVERS_SITEURL}/rettigheter`
const cookiesUrl = `${import.meta.env.VITE_AUNIVERS_SITEURL}/cookies`
</script>

<template>
  <footer class="z-10 m-auto w-full p-5">
    <ul class="flex flex-col flex-wrap justify-center gap-2 text-sm xs:flex-row">
      <li>
        <KsButton
          :icon-left="faHeadphones"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="supportUrl"
          target="_blank"
        >
          {{ t('footer.customerService') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faLampDesk"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="whitelistUrl"
          target="_blank"
        >
          {{ t('footer.whitelist') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faLock"
          variant="tertiary"
          class="text-sm sm:text-base"
          stretch
          :href="privacyUrl"
          target="_blank"
        >
          {{ t('footer.privacy') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faUniversalAccess"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="accessibilityUrl"
          target="_blank"
        >
          {{ t('footer.accessibility') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faBackpack"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="webshopUrl"
          target="_blank"
        >
          {{ t('footer.webshop') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faCopyright"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="copyrightUrl"
          target="_blank"
        >
          {{ t('footer.copyright') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          :icon-left="faCookie"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="cookiesUrl"
          target="_blank"
        >
          {{ t('footer.cookies') }}
        </KsButton>
      </li>
    </ul>
    <ul class="mt-4 flex flex-col flex-wrap justify-center gap-2 text-sm xs:flex-row">
      <li>
        <FeedbackButton
          variant="primary"
          shape="normal"
          size="small"
          :label="true"
          :force-show="true"
          :fixed="false"
          :closable="false"
        />
      </li>
    </ul>
  </footer>
</template>
