import { ref } from 'vue'

export const FOCUSABLE = '[tabindex],a,button:not([disabled]),input:not([disabled]),select:not([disabled]),textarea:not([disabled]),summary,audio,video,iframe,area,[contenteditable],[draggable]'

export const pageTitle = ref('')

export function htmlContainsText(html: string) {
  const el = document.createElement('span')
  el.innerHTML = html ?? ''
  return (el.textContent ?? '').trim().length > 0
}

export function setTitle(title: string) {
  pageTitle.value = `${title} – Aschehoug univers`
  document.title = pageTitle.value
}

export function isElement(node: Node): node is Element {
  return node.nodeType === Node.ELEMENT_NODE
}
