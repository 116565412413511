<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { ref, watch } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import useAccordion from '~/composables/useAccordion'
import ShareContentDialog from '~/components/share/ShareContentDialog.vue'

const props = withDefaults(defineProps<{
  header: ContentProductHeader|ContentProductPackage
  section: ContentPackage
  id: string
  open?: boolean
}>(), {
  open: false
})

const { getAccordion, toggleAccordion, addAccordion } = useAccordion()
const isOpen = ref(false)

const toggle = () => isOpen.value = toggleAccordion(props.header.locationId, props.section.locationId)

watch(props, () => {
  isOpen.value = props.open || getAccordion(props.header.locationId).includes(props.section.locationId)
  if (isOpen.value) addAccordion(props.header.locationId, props.section.locationId)
}, { immediate: true })
</script>

<template>
  <div
    :id="id"
    class="relative mx-auto max-w-screen-au px-4 sm:px-8"
  >
    <h2 class="w-full font-calistoga fluid-text-2xl">
      <span
        :id="`button-${id}`"
        :aria-expanded="isOpen"
        :aria-controls="`section-${id}`"
        class="group flex w-full items-center justify-between pt-12 focus-visible:ring"
        :class="isOpen ? 'pb-8' : 'pb-28'"
        @click="toggle"
      >
        <div class="flex items-center">
          <span
            class="mr-4 transition group-hover:underline group-focus-visible:underline"
            v-text="section.title"
          />
          <ShareContentDialog
            class="pointer-events-auto"
            :resource="section as BaseItem"
            shape="square"
            variant="tertiary"
            size="medium"
            :show-label="false"
            :stretch="false"
            @click.stop
          />
        </div>

        <span class="flex items-center justify-center rounded-full p-1 transition group-hover:bg-seagreen-40 group-focus-visible:bg-seagreen-40">
          <KsIcon
            class="transition-transform duration-300 ease-out"
            :class="isOpen ? 'invisible absolute rotate-90' : 'rotate-0'"
            icon="plus"
          />
          <KsIcon
            class="transition-transform duration-300 ease-out"
            :class="!isOpen ? 'invisible absolute -rotate-90' : 'rotate-0'"
            icon="minus"
          />
        </span>
      </span>
    </h2>
    <div
      :id="`section-${id}`"
      v-disable-tabindex="!isOpen"
      role="region"
      :aria-labelledby="`button-${id}`"
      class="overflow-y-clip overflow-x-visible pb-16"
      :class="!isOpen ? 'pointer-events-none h-20 max-w-screen-au opacity-60 absolute px-4 sm:px-8 bottom-0 left-0' : 'transition-opacity'"
      :aria-hidden="!isOpen"
    >
      <slot />
    </div>
  </div>
</template>
