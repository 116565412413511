import type { RouteMeta } from 'vue-router'
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

function metaWithDefaults(meta: RouteMeta): Required<RouteMeta> {
  return {
    banner: meta.banner ?? false,
    hideAppHeader: meta.hideAppHeader ?? false,
    hideAppFooter: meta.hideAppFooter ?? false,
    returnPath: meta.returnPath ?? '',
    isNavigation: meta.isNavigation ?? false,
    hideNavigation: meta.hideNavigation ?? false,
  }
}

export function useRouteMeta() {
  const route = useRoute()
  const router = useRouter()
  const meta = reactive(metaWithDefaults(route.meta))

  router.afterEach((to) => {
    Object
      .entries(metaWithDefaults(to.meta))
      .forEach(([key, value]) => {
        //@ts-ignore This can safely be ignored as we know each key and value matches the meta object
        meta[key] = value
      })
  })

  return toRefs(meta)
}
