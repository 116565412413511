<script setup lang="ts">
import type { MediaLoadingStrategy } from 'vidstack'
import type { ArticleContentTypes } from '~/models/Content/ContentArticle'
import { computed, provide, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsSpinner } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { colorMap } from '~/utils/colors'
import useLicenseControlStore from '~/stores/licenseControl'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { ArticleContentTypeCriterion, ArticleSize, type ContentArticle } from '~/models/Content/ContentArticle'
import { ColorName } from '~/models/Content/Color'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import ArticleContentSkeleton from '~/components/skeletons/ArticleContentSkeleton.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import ArticleWordlist from '~/components/article/ArticleWordlist.vue'
import ArticleNavigation from '~/components/article/ArticleNavigation.vue'
import ArticleHeaderRenderer from '~/components/article/ArticleHeaderRenderer.vue'
import ArticleContentRenderer from '~/components/article/ArticleContentRenderer.vue'

const props = defineProps<{
  locationId: number
}>()

useScrollBehavior()

provide('embedAudioVariant', 'inline')
provide<MediaLoadingStrategy>('embedAudioLoad', 'visible')

const { t } = useI18n()
const { findContents } = useContentApi()
const { checkItemLicense } = useLicenseControlStore()
const { set: setAppColor } = useAppColor()

const {
  data,
  isLoading,
  isError
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['app-article', props.locationId]),
  queryFn: () => findContents<ContentArticle>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: [ContentType.Article],
    }, 1)
    .then(([data]) => data),
  retry: 1,
})

const {
  data: sections,
  isLoading: isLoadingSections,
  isError: sectionsError
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['app-article-content', props.locationId]),
  queryFn: () => findContents<ArticleContentTypes>({
    contentIdCriterion: data.value?.content?.destinationContentIds,
    contentTypeCriterion: ArticleContentTypeCriterion,
    mainLocationCriterion: true,
    subtreeCriterion: [Subtree.Content, Subtree.Media]
  }, data.value?.content?.destinationContentIds.length),
  select: (articleContent) => {
    const contentIds = data.value?.content?.destinationContentIds ?? []
    return articleContent.sort((a, b) => contentIds.indexOf(a.contentId) - contentIds.indexOf(b.contentId))
  },
  retry: 1,
  enabled: computed(() => (data.value?.content?.destinationContentIds.length ?? 0) > 0)
})

const colors = computed(() => {
  const [defaultColor] = colorMap.values()

  switch (data.value?.colorTheme.identifier) {
    case 'violet':
      return {
        darker: colorMap.get(ColorName.Violet60) ?? defaultColor,
        dark: colorMap.get(ColorName.Violet50) ?? defaultColor,
        pop: colorMap.get(ColorName.YellowPop) ?? defaultColor,
        light: colorMap.get(ColorName.Violet10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Yellow5) ?? defaultColor,
      }

    case 'blue':
      return {
        darker: colorMap.get(ColorName.Blue60) ?? defaultColor,
        dark: colorMap.get(ColorName.Blue50) ?? defaultColor,
        pop: colorMap.get(ColorName.OrangePop) ?? defaultColor,
        light: colorMap.get(ColorName.Orange10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Orange5) ?? defaultColor,
      }

    case 'green':
      return {
        darker: colorMap.get(ColorName.Green60) ?? defaultColor,
        dark: colorMap.get(ColorName.Green50) ?? defaultColor,
        pop: colorMap.get(ColorName.SeagreenPop) ?? defaultColor,
        light: colorMap.get(ColorName.Green10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Green5) ?? defaultColor,
      }

    case 'coral':
    default:
      return {
        darker: colorMap.get(ColorName.Coral60) ?? defaultColor,
        dark: colorMap.get(ColorName.Coral50) ?? defaultColor,
        pop: colorMap.get(ColorName.CoralPop) ?? defaultColor,
        light: colorMap.get(ColorName.Coral10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Coral5) ?? defaultColor,
      }
  }
})

const size = computed(() => data.value?.size.identifier ?? ArticleSize.Medium)

const fontSize = computed(() => {
  switch (size.value) {
    case 'medium': return 1.2
    case 'large': return 1.375
    case 'xlarge': return 2
    default: return 1.2
  }
})

watch(data, () => {
  if (!data.value) return
  setTitle(data.value.title)
  checkItemLicense(data.value)
}, { immediate: true })

watch(colors, (theme) => {
  setAppColor(theme.lighter.name)
}, { immediate: true })
</script>

<template>
  <TopBar
    variant="colorPair"
    :item="data"
    :color-pair="{
      background: colors.lighter,
      text: colors.dark,
      isDark: false,
    }"
  />
  <KsSpinner
    v-if="isLoading"
    position="center"
  />
  <NotFound v-else-if="isError" />
  <article
    v-else
    :style="{
      '--color-lighter': colors.lighter.rgb,
      '--color-light': colors.light.rgb,
      '--color-pop': colors.pop.rgb,
      '--color-dark': colors.dark.rgb,
      '--color-darker': colors.darker.rgb,
      '--media-color': colors.dark.rgb,
      '--theme-5': colors.lighter.rgb,
      '--theme-10': colors.light.rgb,
      '--theme-20': colors.light.rgb,
      '--theme-pop': colors.pop.rgb,
      '--theme-50': colors.dark.rgb,
      '--theme-60': colors.darker.rgb,
    }"
    class="au-article min-h-auto mb-[--col-width] grid auto-rows-max gap-x-[--col-gap] gap-y-[--col-width] bg-[--color-lighter] text-[--color-darker]"
  >
    <!--  Top  -->
    <ArticleHeaderRenderer
      v-if="data"
      :article="data"
    />
    <ArticleWordlist
      v-if="data && data.wordlist.length"
      :item="data"
      :size
    />
    <!--  Sections  -->
    <ArticleContentSkeleton
      v-if="isLoadingSections"
      :color="colors.light.rgb"
    />
    <p
      v-else-if="sectionsError"
      class="col-[text]"
      v-text="t('article.error')"
    />
    <ArticleContentRenderer
      v-else-if="sections?.length"
      :location-id
      :sections
      :size
    />
  </article>
  <!--  Navigation  -->
  <ArticleNavigation
    v-if="data"
    :item="data"
    :colors="{
      text: colors.darker,
      background: colors.light,
      border: colors.darker,
    }"
  />
</template>

<style scoped>
.au-article {
  font-size: calc(1rem * v-bind(fontSize));
  --col-width: 2rem;
  --col-gap: 1rem;

  grid-template-columns:
    [fill-start] minmax(0, 1fr)
    [media-start extext-start text-start] minmax(0, 30ch)
    [midpoint]
    minmax(0, 30ch) [text-end media-end extext-end]
    minmax(0, 1fr) [fill-end];

  @media (min-width: 768px) {
    grid-template-columns:
      [fill-start] minmax(var(--col-gap), 1fr)
      [media-start] minmax(0, var(--col-width))
      [extext-start] minmax(0, var(--col-width))
      [text-start] minmax(0, 30ch)
      [midpoint]
      minmax(0, 30ch) [text-end]
      minmax(0, var(--col-width)) [extext-end]
      minmax(0, var(--col-width)) [media-end]
      minmax(var(--col-gap), 1fr) [fill-end];
  }

  @media (min-width: 1024px) {
    --col-width: 4rem;
    --col-gap: 2rem;
  }

  & :deep(.au-subtitle), & :deep(.cms-h3) {
    font-size: 1.4em;
  }

  & :deep(.au-bigtext) {
    font-size: 2em;
  }

  & :deep(.cms-h) {
    font-weight: 700;

    &.cms-h2 {
      font-size: 1.6em;
    }

    &.cms-h4 {
      font-size: 1.2em;
    }

    &:is(.cms-h5, .cms-h6) {
      font-size: 1em;
    }
  }

  & :deep(.au-title) {
    font-size: 2.8em;
    line-height: 1.25;
  }

  :deep(.au-prose) {
    word-break: break-word;
  }

  :deep(.au-prose > :not(:first-child)) {
    margin-block-start: 1em;
  }

  :deep(.cms-a) {
    color: inherit;
    @apply underline-offset-4;

    &:hover {
      background-color: hsl(from currentColor h s l / .15);
    }

    &:focus-visible {
      @apply ring;
    }
  }

  :deep(.au-prose .cms-ul) {
    list-style-type: disc;
    margin-inline-start: 1em;
  }

  :deep(.au-prose .cms-ol) {
    list-style-type: decimal;
    margin-inline-start: 1em;

    &.upper-latin {
      list-style-type: upper-latin;
    }

    &.lower-latin {
      list-style-type: lower-latin;
    }

    &.upper-roman {
      list-style-type: upper-roman;
    }

    &.lower-roman {
      list-style-type: lower-roman;
    }

    &.decimal-leading-zero {
      list-style-type: decimal-leading-zero;
    }
  }

  :deep(.au-prose .cms-li:not(:first-child)) {
    margin-block-start: .25em;
  }

  :deep(.au-prose .cms-table) {
    border-collapse: separate;
    border-spacing: 0;
    @apply relative bg-white/50 w-full rounded-2xl border border-[--color-light] overflow-clip;

    tr:nth-child(odd) {
      @apply bg-white;
    }

    th {
      @apply bg-[--color-light] text-left;
    }

    :where(td, th) {
      @apply border-r border-b p-4 w-0 border-[--color-dark];

      &:last-child {
        @apply border-r-0
      }
    }

    tbody tr:last-child :where(td, th) {
      @apply border-b-0;
    }
  }

  :deep(.cms-blockquote), :deep(.quoteauthor-textfield) {
    margin-block-end: var(--col-width);
  }

  :deep(.cms-blockquote), :deep(.quoteauthor-textfield blockquote) {
    margin-block-start: var(--col-width);
    font-family: tiempos, serif;
    font-size: 2em;
    position: relative;

    .cms-p {
      display: inline;
      padding-inline: 1rem;
      box-decoration-break: clone;
      background-color: var(--color-light);
    }

    &::before {
      display: block;
      content: '';
      mask-image: url('/quote.svg');
      mask-size: 100%;
      mask-repeat: no-repeat;
      width: 4rem;
      height: 4rem;
      background-color: var(--color-dark);

      @media (min-width: 768px) {
        position: absolute;
        right: calc(100% + 1rem);
        top: -1rem;
      }
    }
  }

  :deep(.quoteauthor-textfield blockquote ~ *) {
    display: inline-block;
    margin-top: 1rem;
    padding-inline: 1rem;
    background-color: var(--color-light);
  }

  :deep(.au-color-box) {
    padding: var(--col-gap);
    gap: var(--col-gap);
    margin-inline: calc(var(--col-gap) * -1);
  }
}
</style>
