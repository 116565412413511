<script setup lang="ts">
import { faQuestion } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss'
import { useIcons } from '~/composables/useIcons'


  defineProps<{
    icon?: string
    title: string
  }>()

  const { duotone } = useIcons()

</script>

<template>
  <div class="au-color-box not-prose flex gap-6 bg-[--theme-10,#fff3] p-6">
    <div
      v-if="icon"
      class="grid flex-none text-lg"
    >
      <KsIcon
        :scale="2"
        :icon="duotone.get(icon.slice(3)) || faQuestion"
        :style="{
          '--fa-primary-color': 'var(--theme-60,black)',
          '--fa-secondary-color': 'var(--theme-pop,#fffa)',
          '--fa-secondary-opacity': '1',
        }"
      />
    </div>

    <div class="au-prose self-center">
      <slot />
    </div>
  </div>
</template>
