<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useStorage } from '@vueuse/core'
import { faHighlighterLine, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss-2'
import { KsButton } from '@aschehoug/kloss'
import { pageTitle } from '~/utils/dom'
import { useAuthStore } from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'

const props = withDefaults(defineProps<{
  variant?: string
  size?: string
  shape?: string
  icon?: object
  classes?: string
  target?: string
  label?: boolean
  forceShow?: boolean
  fixed?: boolean
  closable?: boolean
}>(), {
  variant: 'primary',
  size: 'large',
  shape: 'square',
  icon: faHighlighterLine,
  classes: 'relative',
  target: '_blank',
  label: false,
  forceShow: false,
  fixed: true,
  closable: true,
})

const { t } = useI18n()
const route = useRoute()
const { isTeacher } = storeToRefs(useAuthStore())

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
const newLine = '%0D%0A%0D%0A'

const subject = computed(() => t('reportError.subject', { title: encodeURIComponent(pageTitle.value) }))
const bodyUrl = computed(() => t('reportError.url', { url: encodeURIComponent(location.origin + route.fullPath) }))
const body = computed(() => `${t('reportError.intro')}${newLine}${t('reportError.placeholder')}${newLine}${bodyUrl.value}${newLine}${newLine}`)
const mailtoLink = computed(() => `mailto:${supportEmail}?subject=${subject.value}&body=${body.value}`)
const showFeedbackButton = computed(() => isTeacher.value && (props.forceShow || (locationId.value && !isHidden.value)))
const isHidden = computed(() => {
  if (!locationId.value)
    return false
  return state.value.includes(locationId.value)
})

const locationId = computed((): number | undefined => {
  if (!route.params?.locationId)
    return
  return Number(route.params.locationId)
})

const state = useStorage<number[]>(
  'feedback-button-hidden',
  [],
  localStorage,
)

const hideFeedbackButton = () => {
  if (!locationId.value)
    return
  state.value.push(...state.value, locationId.value)
}
</script>

<template>
  <div
    v-if="showFeedbackButton"
    :class="{ 'fixed': props.fixed, 'bottom-12': props.fixed, 'left-5': props.fixed }"
    class="z-20"
  >
    <div class="relative w-fit">
      <KsButton
        :class="props.classes"
        :variant="props.variant"
        :icon-left="props.icon"
        :shape="props.shape"
        :size="props.size"
        :target="props.target"
        :href="mailtoLink"
        :data-pendo="PendoFeature.Feedback.Button"
      >
        <span v-if="props.label" v-text="t('reportError.button')" />
      </KsButton>
      <button
        v-if="props.closable"
        class="pointer-events-auto absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-white p-1 shadow-lg hover:bg-green-5 focus-visible:ring"
        :data-pendo="PendoFeature.Feedback.HideButton"
        @click.stop="hideFeedbackButton"
      >
        <KsIcon class="self-center text-seagreen-50" :icon="faXmark" />
      </button>
    </div>
  </div>
</template>
