<script setup lang="ts">
import type { FlytMount } from '~/models/Flyt'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { KsCallout } from '@aschehoug/kloss'
import { setFlytProgressLocally } from '~/utils/flyt'
import useAuthStore from '~/stores/auth'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { GradeType } from '~/models/GradeType'
import { getAccessToken } from '~/api/client/oidcClient'

const props = defineProps<{
  taskId: string
  progressId?: number
  theme?: string
  mode?: string
  assignment?: boolean
}>()

const { t } = useI18n()
const route = useRoute()
const { selectedGradeType, userPreferredLanguage, isTeacher } = storeToRefs(useAuthStore())

const flytContainerRef = ref<HTMLElement | null>(null)
const failedToLoad = ref(false)
let cleanup: (() => void) | null = null

const flytGradeType = computed(() => selectedGradeType.value && [
  GradeType.LowerPrimary,
  GradeType.UpperPrimary,
].includes(selectedGradeType.value)
  ? 'UpperPrimary'
  : '')

const loadTask = async () => {
  const el = flytContainerRef.value!
  const flytModule = await import('remote_app/flyt') as FlytMount
  cleanup = flytModule.default.mount(el as HTMLElement, {
    flytId: props.taskId,
    ...(props.assignment && { assignmentId: `flyt_test-${props.taskId}` }),
    theme: 'aunivers',
    gradeType: flytGradeType.value,
    getAuthToken: () => getAccessToken(),
    endpoint: import.meta.env.VITE_FLYT_ENGINE,
    ...(props.theme && { theme: props.theme }),
    ...(props.mode && { mode: props.mode }),
    callbacks: {
      onErrorCaptured(e: Error) {
        console.error(e)
        failedToLoad.value = true
      },
      onProgress(totalItems: number, itemsAnswered: number) {
        if (route.params.taskId && props.progressId && !isTeacher.value) {
          return setFlytProgressLocally(props.progressId, totalItems, itemsAnswered)
        }
      },
    },
  })
}

onMounted(() => {
  loadTask()
})

watch(props, () => {
  cleanup?.()
  loadTask()
})

onBeforeUnmount(() => {
  cleanup?.()
})
</script>

<template>
  <section class="mx-auto motion-safe:animate-fade">
    <div
      v-if="!failedToLoad"
      ref="flytContainerRef"
    />
    <div v-else>
      <KsCallout variant="error">
        {{ t('unknownError') }}
      </KsCallout>
    </div>
    <KsCallout
      v-if="userPreferredLanguage === PreferredLanguage.Nynorsk"
      variant="info"
      class="m-2"
    >
      {{ t('flyt.missingTranslationMessage') }}
    </KsCallout>
  </section>
</template>

<style>
  .FlytApp {
  padding: 40px 0;
  border-radius: 20px;
}
</style>
