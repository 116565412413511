export enum Navigation {
  Home = 'Navigasjon.Hjem',
  Search = 'Navigasjon.Søk',
  Planner = 'Navigasjon.Planlegger',
  Settings = 'Navigasjon.Innstillinger',
  StudentActivity = 'Navigasjon.Elevaktivitet',
  Burger = 'Navigasjon.Burger',
  Favorites = 'Navigasjon.Favoritter',
  HomeButton = 'Navigasjon.Forsideknapp',
}

export enum Presentation {
  Next = 'Presentasjon.NesteSide',
  Previous = 'Presentasjon.ForrigeSide',
  Overview = 'Presentasjon.Innholdsoversikt',
  Exit = 'Presentasjon.Lukk',
  Fullscreen = 'Presentasjon.Fullskjerm',
}

export enum TeacherContent {
  Subject = 'Lærerinnhold.Fagside',
  Header = 'Lærerinnhold.Temaside',
  Back = 'Lærerinnhold.Tilbake',
}

export enum Colophon {
  Subject = 'Kolofon.Fagside',
  Back = 'Kolofon.Tilbake',
}

export enum Card {
  CopyUrl = 'Kort.KopierUrl',
}

export enum Settings {
  Logout = 'Innstillinger.LoggUt',
  ChangeGradeType = 'Innstillinger.EndreSkoleslag',
  ChangeLanguage = 'Innstillinger.EndreSpråk',
  Groups = 'Innstillinger.Grupper',
  SuggestedPlans = 'Innstillinger.SuggestedPlans',
  Subscriptions = 'Innstillinger.Abonnement',
  ShortcutUnibok = 'Innstillinger.SnarveiUnibok',
  ShortcutBlog = 'Innstillinger.SnarveiBlogg',
  ShortcutLokus = 'Innstillinger.SnarveiLokus',
}

export enum Favorites {
  OpenList = 'Favoritter.ÅpneListe',
  CreateList = 'Favoritter.LagNyListe',
  ListBack = 'Favoritter.TilbakeFraListe',
  DeleteList = 'Favoritter.SlettListe',
  ChangeList = 'Favoritter.EndreListe',
  ChangeName = 'Favoritter.EndreNavn',
  Add = 'Favoritter.LeggTil',
  Remove = 'Favoritter.Fjern',
  Open = 'Favoritter.Åpne',
  Plan = 'Favoritter.Planlegg',
}

export enum Planner {
  AddToWeek = 'Planlegger.LeggTilUke',
  OpenResource = 'Planlegger.ÅpneRessurs',
  RemoveResource = 'Planlegger.FjernRessurs',
  CopySuggestedPlan = 'Planlegger.KopierForeslåttPlan',
  UploadFile = 'Planlegger.LastOppFil',
  DownloadFile = 'Planlegger.LastNedFil',
  ShareUrl = 'Planlegger.DelEksternUrl',
}

export enum TermExam {
  CreateTask = 'Terminprøve.OpprettPrøve',
  SelectStudent = 'Terminprøve.VelgElev',
  SaveTask = 'Terminprøve.LagrePrøve',
  StartTask = 'Terminprøve.StartPrøve',
  DeleteTask = 'Terminprøve.SlettPrøve',
  CloseStudent = 'Terminprøve.StengElev',
  OpenStudent = 'Terminprøve.ÅpneElev',
  CloseTask = 'Terminprøve.StengPrøve',
  GoToTask = 'Terminprøve.GåTilPrøve',
  NavigateTask = 'Terminprøve.NavigerPrøve',
}

export enum Report {
  ToggleAccordion = 'Rapport.ToggleElev',
  FilterGroup = 'Rapport.FiltrerGruppe',
  FilterGrade = 'Rapport.FiltrerTrinn',
}

export enum Share {
  Simple = 'Deling.Enkel',
}

export enum Feedback {
  Button = 'Tilbakemelding.Knapp',
  HideButton = 'Tilbakemelding.SkjulKnapp',
}
