import { ref, computed } from 'vue'

const featureFlagPrefix = 'VITE_FEATURE_FLAG_'
const featureFlags = ref<{ [key: string]: boolean }>({})
let initialized = false

export function useFeatureFlag() {

  const evaluateEnvVar = (envVar: unknown) => typeof envVar === 'string' && envVar === 'true'

  const setFeatureFlag = (flag: string, value: boolean) => {
    featureFlags.value[flag] = value
  }

  const getFeatureName = (key: string) => key.replace(featureFlagPrefix, '')

  const initFlags = () => {
    if (initialized) return
    const envVars = import.meta.env as { [key: string]: unknown }
    Object.keys(envVars).forEach(envVar => {
      if(envVar.includes(featureFlagPrefix)) {
        setFeatureFlag(getFeatureName(envVar), evaluateEnvVar(envVars[envVar]))
      }
    })
    initialized = true
  }

  const isFeatureEnabled = computed(() => (flag: string) => {
    if (!initialized) initFlags()
    return featureFlags.value[flag] ?? false
  })

  return {
    isFeatureEnabled: isFeatureEnabled.value,
  }
}
