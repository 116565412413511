import type { UserSource } from '~/models/User/UserSource'
import type { UserRole } from '~/models/User/UserRole'
import type { PreferredLanguage } from '~/models/User/PreferredLanguage'
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import {
  Card,
  Colophon,
  Favorites,
  Feedback,
  Navigation,
  Planner,
  Presentation,
  Report,
  Settings,
  Share,
  TeacherContent,
  TermExam,
} from '~/models/PendoFeature'

export interface PendoVisitor {
  id: string
  role: UserRole
  organizationName: string
  organizationNumber: string
  loggedIn: boolean
  locale: PreferredLanguage
  grades: GradeCode[]
  readOnlyGrades: string[]
  subjects: SubjectCode[]
  trialOnly?: boolean // Should only be set when we actually know the value, not initialized as e.g. false
  source?: UserSource
}

export interface PendoAccount {
  id: string
  organizationName: string
  organizationNumber: string
  trialOnly?: boolean
}

export interface PendoUser {
  visitor: PendoVisitor
  account: PendoAccount
  events?: PendoEvents
}

export interface PendoEvents {
  ready: () => void
}

export type PendoRating = 'positive' | 'negative' | 'neutral'

export type PendoTrackData = Record<string, string | number | string[] | number[]>

export enum PendoTrackName {
  SearchPage = 'SEARCH_PAGE',
  LoginButton = 'LOGIN_BUTTON',
  SubjectSearch = 'SUBJECT_SEARCH',
  Rating = 'RATING',
  Chat = 'CHAT',
  FlashcardReveal = 'FLASHCARD_REVEAL',
  FlashcardComplete = 'FLASHCARD_COMPLETE',
  BannerArticle = 'BANNER_ARTICLE',
}

export enum PendoEventName {
  Ready = 'pendo:ready',
}

export const PendoFeature = {
  Navigation,
  Presentation,
  TeacherContent,
  Colophon,
  Card,
  Settings,
  Favorites,
  Planner,
  TermExam,
  Report,
  Share,
  Feedback,
}
