<script setup lang="ts">
import type { ContentArticle } from '~/models/Content/ContentArticle'
import type { Color } from '~/models/Content/Color'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import { ContentType } from '~/models/Content/ContentType'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useSearchHelper from '~/composables/useSearchHelper'
import useContentApi from '~/api/contentApi'
import ArticleNavigationCard from '~/components/article/ArticleNavigationCard.vue'

const { item, colors } = defineProps<{
  item: BaseItem
  colors: {
    text: Color
    background: Color
    border: Color
  }
}>()

const router = useRouter()
const { findContents } = useContentApi()
const { cardFields } = useSearchHelper()
const { Terminprover } = useStaticProduct()

const { data: siblings } = useQuery({
  staleTime: Infinity,
  enabled: computed(() => item.parentLocationContentTypeIdentifier === ContentType.ProductHeader
    && item.pathString.startsWith(Terminprover.aunivers.pathString!)),
  queryKey: computed(() => ['article-siblings', item.parentLocationId]),
  queryFn: () => findContents<ContentArticle>({
    parentLocationIdCriterion: [item.parentLocationId],
    sortField: item.parentLocationSortField,
    sortOrder: item.parentLocationSortOrder,
  }, 100, 0, cardFields)
})

const showNavigation = computed(() => !router.currentRoute.value.meta?.hideNavigation)

const index = computed(() => Number(siblings?.value?.findIndex(({ locationId }) => locationId === item.locationId)))
const previous = computed(() => siblings?.value?.[index.value-1])
const next = computed(() => siblings?.value?.[index.value+1])
</script>
<template>
  <div
    v-if="showNavigation && (previous || next)"
    :style="{
      '--color-text': colors.text.rgb,
      '--color-background': colors.background.rgb,
      '--color-border': colors.border.rgb,
    }"
    class="sticky top-[100vh] flex grow border-t-2 border-t-[--color-border] bg-[--color-background] text-[--color-text]"
    :class="!previous ? 'justify-end' : 'justify-between'"
  >
    <ArticleNavigationCard
      :item="previous"
      direction="previous"
    />
    <ArticleNavigationCard
      :item="next"
      direction="next"
    />
  </div>
</template>
