<script setup lang="ts">
import type { Group } from '~/models/Group'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { Task, TaskUser } from '~/models/AssignTask'
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { faArrowTurnRight } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsSpinner, KsTextArea } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import useGroupsStore from '~/stores/groups'
import { useAuthStore } from '~/stores/auth'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { LocationType, ShareTag, TaskStatus } from '~/models/AssignTask'
import { useFeatureFlag } from '~/composables/useFeatureFlag'
import { useContentHelper } from '~/composables/useContentHelper'
import { useAssignTask } from '~/composables/useAssignTask'
import GroupSelectionDropdown from '~/components/products/exams/assignment/GroupSelectionDropdown.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  size?: 'small' | 'medium'
  shape?: 'normal' | 'square'
  variant?: 'secondary' | 'tertiary'
  showLabel?: boolean
  stretch?: boolean
}>(), {
  size: 'medium',
  shape: 'square',
  variant: 'secondary',
  showLabel: false,
  stretch: false
})
const { t } = useI18n()
const { getProductFromResource } = useProductStore()
const { hasGroupMembers, findGroupMembers, loadGroupMembers } = useGroupsStore()
const { shareSimple } = useAssignTask()
const { isPackage } = useContentHelper()
const { isFeatureEnabled } = useFeatureFlag()
const { hasOrganization, isTeacher } = storeToRefs(useAuthStore())

const dialogOpen = ref(false)
const isLoadingGroups = ref(false)
const isSharing = ref(false)
const selectedGroup = ref<Group>()
const taskUsers = ref<TaskUser[]>([])
const textareaValue = ref('')

const showShareButton = computed(() => isFeatureEnabled('SHARE_DIALOG') && isTeacher.value && hasOrganization.value)
const showMessageField = computed(() => isFeatureEnabled('SHARE_MESSAGE'))
const product = computed(() => getProductFromResource(props.resource))

const buttonProps = computed(() => ({
  'resource': props.resource,
  'size': props.size,
  'shape': props.shape,
  'variant': props.variant,
  'icon-left': faArrowTurnRight,
  'aria-label': t('share.messages.share'),
  'stretch': props.stretch,
}))

const locationType = computed((): LocationType => {
  return isPackage(props.resource) ? LocationType.ParentLocation : LocationType.Location
})

const task = computed((): Partial<Task> => {
  return {
    name: props.resource.title,
    groups: selectedGroup.value?.groupId ? [selectedGroup.value?.groupId] : [],
    users: taskUsers.value,
    grades: props.resource.grades,
    status: TaskStatus.Open,
    subjects: product.value?.subjects,
    locationIds: [props.resource.locationId],
    locationType: locationType.value,
    pathString: product.value?.aunivers.pathString,
    tags: [ShareTag.Basic],
    message: textareaValue.value
  }
})

const canSubmit = computed((): boolean => {
  return !isLoadingGroups.value && !!selectedGroup.value && !!taskUsers.value.length
})

const getGroupMembers = async () => {
  if (!selectedGroup.value)
    return

  if (hasGroupMembers(selectedGroup.value))
    mapAndSetTaskUsersFromGroupMembers()

  isLoadingGroups.value = true
  await loadGroupMembers(selectedGroup.value)
  isLoadingGroups.value = false

  if (hasGroupMembers(selectedGroup.value))
    mapAndSetTaskUsersFromGroupMembers()
}

const mapAndSetTaskUsersFromGroupMembers = () => {
  if (!selectedGroup.value)
    return
  taskUsers.value = findGroupMembers(selectedGroup.value).map(({ userId, firstName, lastName, fullName, role }) => ({
    userId,
    status: TaskStatus.Open,
    firstName,
    lastName,
    fullName,
    primaryRole: role,
  }))
}

const createTask = async () => {
  isSharing.value = true
  await shareSimple.mutateAsync({
    newTask: task.value,
    users: { users: task.value?.users || [] },
  })
  isSharing.value = false
  dialogOpen.value = false
}

const setTextAreaValue = (value: string) => {
  textareaValue.value = value
}

watch(selectedGroup, async () => {
  await getGroupMembers()
})
</script>

<template>
  <KsButton
    v-if="showShareButton"
    v-bind="buttonProps"
    @click.stop="dialogOpen = true"
  >
    {{ props.showLabel ? t('share.messages.share') : '' }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('share.title', { name: props.resource.title })"
      :open="dialogOpen"
      :strict="false"
      size="small"
      @close="dialogOpen = false"
    >
      <template #body>
        <form class="flex flex-col gap-4">
          <GroupSelectionDropdown v-model="selectedGroup" />
          <label v-if="showMessageField" class="space-y-2">
            <span
              class="text-base font-medium"
              v-text="t('share.messages.optionalMessage')"
            />
            <KsTextArea
              :placeholder="t('share.placeholders.messageField')"
              rows="5"
              @input="setTextAreaValue($event.target.value)"
            />
          </label>
        </form>
      </template>
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <KsButton
            variant="primary"
            :disabled="!canSubmit"
            shape="rounded"
            :data-pendo="PendoFeature.Share.Simple"
            @click="createTask"
          >
            <div class="flex gap-1">
              <p v-text="isSharing ? t('share.messages.sharing') : t('share.messages.share')" />
              <KsSpinner
                v-if="isSharing"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
                size="small"
              />
            </div>
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
