import type { DeepNullable } from '~/models/Utilities'
import type { UserRole } from '~/models/User/UserRole'
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'

export enum TaskStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum ShareTag {
  Basic = 'share',
}

export enum LocationType {
  Location = 'LOCATION',
  ParentLocation = 'PARENT_LOCATION',
}

export interface Response {
  response: Array<Task>
  resultCount: number
}

export type Task = DeepNullable<{
  taskId: string
  name: string
  grades: GradeCode[]
  subjects: SubjectCode[]
  groups: string[]
  locationIds: number[]
  users: TaskUser[]
  locationType: LocationType
  pathString: string
  status: TaskStatus
  startDate: string
  duration: number
  tags: (string | ShareTag)[]
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  message?: string
}>

export type TaskUser = DeepNullable<{
  userId: string
  status: TaskStatus
  firstName: string
  lastName: string
  fullName: string
  primaryRole: UserRole
}>

export interface TaskUserRequest {
  users: Pick<TaskUser, 'userId' | 'status'>[]
}

export interface TaskQueryParams {
  groups?: string[]
  tags?: string[]
  subjects?: string[]
  grades?: string[]
  [key: string]: string[] | undefined
}
