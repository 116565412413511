<script setup lang="ts">
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { faQuestion } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon, KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { useMedia } from '~/composables/useMedia'
import { useIcons } from '~/composables/useIcons'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenContent from '~/components/utils/FullscreenContent.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const { duotone } = useIcons()
const { data: media, isFetching } = firstOf(useMedia([Number(props.articleContent.image?.destinationContentId)]))
const reverse = computed(() => props.articleContent.reverse)
</script>

<template>
  <div
    class="relative grid grid-cols-subgrid items-center gap-y-[--col-width] bg-[--color-light] p-[--col-gap]"
    :class="[media ? 'col-[media] py-[--col-width]' : 'col-[text] md:-mx-[--col-gap]', {
      'py-[calc(var(--col-gap)*1.5)]': articleContent.icon?.identifier,
    }]"
  >
    <KsSkeleton
      v-if="isFetching"
      class="col-span-5 row-start-1"
      :class="reverse ? '-col-end-3' : 'col-start-3'"
      height="300px"
    />
    <div
      v-else-if="media"
      class="col-[extext] row-start-1"
      :class="reverse ? 'md:col-start-[midpoint]' : 'md:col-end-[midpoint]'"
    >
      <FullscreenContent>
        <template #default="{ isFullscreen }">
          <Image
            :content="media"
            class="col-start-1 row-start-1 max-h-[90lvh] !w-full"
            :class="isFullscreen ? 'object-contain p-4 place-self-center' : 'object-cover'"
            :width="isFullscreen ? '100vw' : '400px'"
          />

          <div
            v-if="'copyright' in media && media.copyright"
            class="row-start-2 flex flex-col place-self-stretch bg-white p-4"
          >
            <RichTextRenderer :text="media.caption" />
            <small v-if="media.copyright">{{ $t('copyright.image', { copyright: media.copyright }) }}</small>
          </div>
        </template>
      </FullscreenContent>
    </div>

    <div
      class="au-prose col-[1/-1] md:row-start-1"
      :class="{
        'md:col-[extext/midpoint]': (media || isFetching) && reverse,
        'md:col-[midpoint/extext]': (media || isFetching) && !reverse,
      }"
    >
      <RichTextRenderer :text="articleContent.body" />
    </div>

    <div
      v-if="articleContent.icon?.identifier"
      class="absolute left-1/2 top-0 grid -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-[--theme-5] bg-[--theme-20] p-4 text-lg"
    >
      <KsIcon
        :icon="duotone.get(articleContent.icon.identifier) || faQuestion"
        :scale="1.5"
        :style="{
          '--fa-primary-color': 'var(--theme-60)',
          '--fa-secondary-color': 'var(--theme-pop)',
          '--fa-secondary-opacity': '1',
        }"
      />
    </div>
  </div>
</template>
