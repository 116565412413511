import { faAlignLeft, faBookOpen, faBooks, faBowlChopsticksNoodles, faBrain, faComments, faEyes, faHighlighter, faLightbulbOn, faListUl, faMindShare, faPenAlt, faPencil, faPencilRuler, faQuestion, faSearch, faUniversalAccess, faVideo } from '@fortawesome/pro-duotone-svg-icons'

/** A composable to keep track of dynamic icons */
export function useIcons() {
  const duotone = new Map([
    ['comments', faComments],
    ['pencil-ruler', faPencilRuler],
    ['pencil', faPencil],
    ['video', faVideo],
    ['universal-access', faUniversalAccess],
    ['book-open', faBookOpen],
    ['list-ul', faListUl],
    ['align-left', faAlignLeft],
    ['search', faSearch],
    ['question', faQuestion],
    ['mind-share', faMindShare],
    ['brain', faBrain],
    ['books', faBooks],
    ['pen-alt', faPenAlt],
    ['highlighter', faHighlighter],
    ['lightbulb-on', faLightbulbOn],
    ['bowl-chopsticks-noodles', faBowlChopsticksNoodles],
    ['eyes', faEyes],
  ])

  return {
    duotone
  }
}
