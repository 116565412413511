<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Product, SupportProductName } from '~/models/Product'
import { RouterLink } from 'vue-router'
import { storeToRefs } from 'pinia'
import { KsButton, KsDialog } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useText from '~/composables/useText'
import useSubjectButton from '~/composables/useSubjectButton'
import useProductHelper from '~/composables/useProductHelper'

const props = defineProps<{
  name: string
  products: Product[]
  subjects: SubjectCode[]
}>()

const emit = defineEmits(['close'])
const { resolveBaseUrl } = useUrlBuilder()
const { capitalize } = useText()
const { getStyles } = useSubjectButton()
const { isLowerPrimaryUser } = storeToRefs(useAuthStore())

const { isSupportName } = useProductHelper()
const { selectedSupportProductName } = storeToRefs(useProductStore())

const selectedSupportProducts = (selected: SupportProductName) => {
  return props.products.filter(product => isSupportName(product, selected))
}
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="capitalize(name)"
      :open="selectedSupportProductName === name"
      @close="emit('close')"
    >
      <template #body>
        <ul
          v-if="products.length"
          class="grid flex-col gap-2"
        >
          <li
            v-for="(product, index) in selectedSupportProducts(name)"
            :key="product.ean"
          >
            <KsButton
              :is="RouterLink"
              :to="{ path: resolveBaseUrl(product) }"
              variant="primary"
              stretch
              center
              :class="{ 'subject-button': isLowerPrimaryUser }"
              class="!text-2xl !font-bold"
              :style="isLowerPrimaryUser ? getStyles(subjects.length + index) : {}"
              :data-pendo="PendoFeature.Navigation.HomeButton"
            >
              {{ product.aunivers.name }}
            </KsButton>
          </li>
        </ul>
      </template>
    </KsDialog>
  </Teleport>
</template>

<style scoped>
.subject-button {
  --ks-primary: #002422;
  padding: 1.2em 1em;
}
.subject-button:hover {
  --ks-primarytext: #002422 !important;
}
</style>
